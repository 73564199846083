<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354.04 354.04"><path :fill="color" d="M193.21,258V209.66H160.84V258H113.71c-6.22,0-9.48-3.25-9.48-9.43q0-29.1,0-58.21a3.22,3.22,0,0,1,1.32-2.82q31.18-25.64,62.33-51.32c2.57-2.12,5.19-4.19,7.7-6.39,1.08-1,1.8-.93,2.92,0Q199.15,147,219.91,164q14.35,11.83,28.73,23.63a3.14,3.14,0,0,1,1.19,2.73q0,29.35,0,58.7c0,5.51-3.41,8.93-9,8.94q-21.53,0-43.06,0Z"/><path :fill="color" d="M177,117.64l-24.59,20.49L90.46,189.7c-2.83,2.35-4.94,2.16-7.29-.64q-3.23-3.83-6.42-7.67c-2.29-2.74-2.15-4.84.62-7.15q17-14.22,34.09-28.4,26.87-22.35,53.71-44.73c3-2.49,6.05-4.65,10.12-5a15.45,15.45,0,0,1,11.78,3.55l28.82,24c.41.34.84.65,1.54,1.18V122.8q0-10.59,0-21.19c0-3.9,1.41-5.29,5.34-5.3h21.7c3.91,0,5.33,1.42,5.33,5.32q0,24.36,0,48.71a2.92,2.92,0,0,0,1.14,2.54q12.38,10.19,24.66,20.45c.56.46,1.12.91,1.65,1.4,1.91,1.79,2.22,3.9.61,5.93-2.45,3.09-5,6.13-7.6,9.08-1.86,2.09-4.09,2.11-6.35.23q-14.62-12.15-29.22-24.34L178.8,119.07C178.25,118.61,177.67,118.19,177,117.64Z"/></svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#000000' 
            },
        },
    }
</script>
